<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-col lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Horarios'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>

                        <template slot="filters">
                            <v-col xs="12" sm="6" md="6" class="py-0" >
                                <v-text-field
                                outlined
                                label="Nombre"
                                v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="jornadas"
                                    item-text="descripcion"
                                    item-value="id"
                                    label="Tipo jornada"
                                    persistent-hint
                                    v-model="jornada_id"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="6" md="12" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                        no-data-text="Datos no disponibles"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                        no-data-text="Datos no disponibles"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col xs="12" sm="12" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                        no-data-text="Datos no disponibles"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td v-if="rol == 'root'">
                                    <div v-if="item.empresa" class="tblPrincipal">{{ item.empresa.nombre }}</div>
                                </td>
                                <td v-if="rol == 'root'">
                                    <div v-if="item.sucursal" class="tblPrincipal">{{ item.sucursal.nombre }}</div>
                                </td>

                                <td v-if="rol == 'admin'">
                                    <div v-if="item.empresa" class="tblPrincipal">{{ item.empresa.nombre }}</div>
                                </td>
                                <td v-if="rol == 'admin'">
                                    <div v-if="item.sucursal" class="tblPrincipal">{{ item.sucursal.nombre }}</div>
                                </td>

                                <td v-if="rol == 'admin-empresa'">
                                    <div v-if="item.sucursal" class="tblPrincipal">{{ item.sucursal.nombre }}</div>
                                </td>

                                <td v-if="rol == 'admin-sucursal'">
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>

                                <td v-if="rol != 'admin-sucursal'">
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.descripcion }}</div>
                                </td>

                                <td v-if="rol == 'admin-depto'">
                                    <div class="tblPrincipal">{{ item.minutos_tolerancia }}</div>
                                </td>

                                <td v-if="rol != 'admin-depto' && rol != 'empleado'">
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="eliminar(item.id, item.nombre)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
            <template>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                    <img :src="modalImagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="loadingModal" v-show="!finaliza_carga" >
                                        <div id="loading">
                                            <v-progress-circular
                                            indeterminate
                                            :size="100"
                                            :width="7"
                                            color="blue"
                                            ></v-progress-circular>
                                        </div>
                                    </div>

                                    <v-container v-show="finaliza_carga" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col xs="12" sm="8" md="8" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field
                                                        autofocus
                                                        id="primero"
                                                        outlined
                                                        label="Nombre"
                                                        v-model="horario.nombre"
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="4" md="4" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Minutos tolerancia" rules="required|numeros_positivos">
                                                    <v-text-field
                                                        outlined
                                                        label="Minutos tolerancia"
                                                        v-model="horario.minutos_tolerancia"
                                                        v-mask="'####'"
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Descripción"
                                                        v-model="horario.descripcion"
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <template>
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Tipo jornada" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="jornadas"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            label="Tipo jornada"
                                                            persistent-hint
                                                            v-model="horario.jornada_id"
                                                            :error-messages="errors"
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root'">
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            :error-messages="errors"
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin'">
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="sucursales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Sucursal"
                                                            persistent-hint
                                                            v-model="horario.sucursal_id"
                                                            :error-messages="errors"
                                                            required
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <v-col xs="12" sm="12" md="12" class="py-0 pl-3">
                                                <h4 class="selDias">Seleccionar días</h4>
                                            </v-col>
                                            
                                            <div class="centrar">
                                                <v-checkbox
                                                    v-for="(d, index) in semanaHorarios"
                                                    :key="index"
                                                    hide-details
                                                    class="shrink mr-4 mt-0 dias"
                                                    v-model="dias_seleccionados"
                                                    :label="d.dia"
                                                    :value="d.dia"
                                                    :disabled="d.desabilitar"
                                                ></v-checkbox>
                                            </div>
                                        </v-row>
                                        <v-row>
                                            <v-col v-if="!navegador" xs="12" sm="5" md="5" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Hora entrada"
                                                    v-model="hora_entrada"
                                                    id="hora_entrada"
                                                    type="time"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col v-else xs="12" sm="5" md="5" class="py-0">
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu1"
                                                    :close-on-content-click="false"
                                                    :nudge-right="10"
                                                    :return-value.sync="time"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="hora_entrada"
                                                            label="Hora entrada"
                                                            readonly
                                                            outlined
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                        v-if="menu1"
                                                        v-model="hora_entrada"
                                                        full-widthformat="24hr"
                                                        scrollable
                                                        ampm-in-title
                                                        @click:minute="$refs.menu.save(hora_entrada)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!navegador" xs="12" sm="5" md="5" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Hora salida"
                                                    v-model="hora_salida"
                                                    id="hora_salida"
                                                    type="time"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col v-else xs="12" sm="5" md="5" class="py-0">
                                                <v-menu
                                                    ref="menuFin"
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="time"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="hora_salida"
                                                            label="Hora salida"
                                                            readonly
                                                            outlined
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                        v-if="menu2"
                                                        v-model="hora_salida"
                                                        full-widthformat="24hr"
                                                        scrollable
                                                        ampm-in-title
                                                        @click:minute="$refs.menuFin.save(hora_salida)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col xs="12" sm="2" md="2" class="py-0">
                                                <v-btn
                                                    color="#1E2245"
                                                    elevation="0"
                                                    class="mt-2"
                                                    dark
                                                    fab
                                                    @click="agregarHorario()"
                                                    small
                                                >
                                                    <v-icon dark>add</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="3" md="4" class="py-0">
                                                <v-input disabled class="pl-2">Día</v-input>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="3" md="3" class="py-0">
                                                <v-input disabled class="pl-2">Entrada</v-input>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="3" md="3" class="py-0">
                                                <v-input disabled class="pl-2">Salida</v-input>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <template v-for="(i, index) in horario.detallesHorarios">
                                                <v-col col="12" xs="12" sm="3" md="4" :key="index" class="detalle">
                                                    <v-text-field
                                                        id="file_input_file"
                                                        label="Dia"
                                                        disabled
                                                        class
                                                        type="text"
                                                        :value="i.dia"
                                                        outlined
                                                        single-line
                                                        dense
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col xs="12" sm="3" md="3" :key="'A'+index" class="detalle">
                                                    <v-text-field
                                                        label="entrada"
                                                        type="text"
                                                        :value="i.hora_entrada"
                                                        disabled
                                                        outlined
                                                        single-line
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col xs="12" sm="3" md="3" :key="'B'+index" class="detalle">
                                                    <v-text-field
                                                        label="salida"
                                                        type="text"
                                                        :value="i.hora_salida"
                                                        disabled
                                                        outlined
                                                        single-line
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col xs="12" sm="2" md="2" :key="'C'+index" class="detalle">
                                                    <v-btn
                                                        :key="index"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        small
                                                        @click="eliminaHorario(index, i.id)"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions v-show="finaliza_carga" class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="loading_guardar"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="loading_guardar"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>

                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Notify from "@/plugins/notify";
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Horarios';
import horariosApi from "../api/horarios";
import clientesApi from "../api/clientes";
import empresaApi from "../api/empresas";
import sucursalApi from "../api/sucursales";
import apiTipoJornada from "../api/nominas/tipoJornada";

export default {
    name: "Horario",
    components: {
        "data-table": Datatable,
    },
    data() {
        return {
            filters: {AND:[]},
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            advancedSearch: {
                nombre: null,
            },
            tableData:{},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            loading_guardar : false,
            loading         : true,
            isLoading       : true,
            dialog          : false,
            search          : "",
            datosLogin      : "",
            horario: {
                id                  : 0,
                nombre              : "",
                descripcion         : "",
                minutos_tolerancia  : "",
                cliente_id          : "",
                empresa_id          : "",
                sucursal_id         : "",
                detallesHorarios    : [],
                detalles_eliminados : [],
                jornada_id          : null,
            },
            dias_seleccionados: [],
            semanaHorarios: [
                { dia: "Lunes", desabilitar: false },
                { dia: "Martes", desabilitar: false },
                { dia: "Miércoles", desabilitar: false },
                { dia: "Jueves", desabilitar: false },
                { dia: "Viernes", desabilitar: false },
                { dia: "Sábado", desabilitar: false },
                { dia: "Domingo", desabilitar: false },
            ],
            semana: [
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado",
                "Domingo",
            ],
            semanaOriginal: [
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado",
                "Domingo",
            ],
            dia             : "",
            nombre          :"",
            hora_entrada    : "",
            hora_salida     : "",
            datosLogin      : "",
            titulo_modal    : "",
            finaliza_carga  : false,
            parametros_base : { activo: true, paginate: false },
            action          : "",
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            departamentos   : [],
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            jornada_id      : null,
            rol             : "",
            dias_eliminados : [],
            jornadas        : [],
            menu1           : false,
            menu2           : false,
            navegador       :false,
            modalImagen     : '',
            jornada_nocturna : false,

            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        };
    },
    watch: {
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        empresa_value: function(val) {
            this.cambiaEmpresa(val);
        },
        sucursal_value: function(val){
            //this.cambiaSucursal(val);
        },
        "horario.jornada_id"(value){
            for(let jornada of this.jornadas){
                if(jornada.id == value){
                    if(jornada.clave === "02"){
                        this.jornada_nocturna = true;
                    }else{
                        this.jornada_nocturna = false;
                    }
                }
            }
        }
    },
    methods: {
        /**
         * @method eliminar Método que elimina un horario
         * @description Método que elimina un horario
         * @param {int} id Id del horario a eliminar
         * @param {string} horario Nombre del horario a eliminar
         */
        eliminar(id, horario) {
            Notify.Alert(
                "¿Estás seguro de eliminar este horario?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    horariosApi.deleteHorario(id).then((response) => {
                        this.$apollo.queries.horarios.refetch();
                        this.respuesta_ok("Operación exitosa", "El horario ha sido eliminado satisfactoriamente.");
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            );
        },

        /**
         * @method listar Método para listar en tabla
         * @description Este metodo sirve para listar los horarios en la tabla segun sea el rol
         */
        listar() {
            this.loading = true;
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
           
            if (this.rol == "admin-depto") {
                depto = this.datosLogin.departamento_id;
            }
            
            this.getTipoJornada();

            if (this.rol == "root") {
                this.getClientes();
                this.columns.unshift({
                    label: "Empresa",
                    name: "empresa",
                    filterable: false,
                },
                {
                    label: "Sucursal",
                    name: "sucursal",
                    filterable: false,
                });
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.cliente_value});
                this.columns.unshift({
                    label: "Empresa",
                    name: "empresa",
                    filterable: false,
                },
                {
                    label: "Sucursal",
                    name: "sucursal",
                    filterable: false,
                });
                this.horario.cliente_id = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.empresa_value = this.datosLogin.empresa_id;
                this.filters.AND.push({column:'EMPRESA_ID', value:this.empresa_value});
                this.columns.unshift({
                    label: "Sucursal",
                    name: "sucursal",
                    filterable: false,
                });
                this.horario.empresa_id  = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.filters.AND.push({column:'SUCURSAL_ID', value:this.sucursal_value});
                this.horario.sucursal_id = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                departamento_id  = this.datosLogin.departamento_id;
                this.columns.pop();
                this.columns.unshift({
                    label: "Minutos de tolerancia",
                    name: "minutos_tolerancia",
                    filterable: true,
                });
            }
        },

        /**
         * @method getPaginationInfo Método para la paginación
         * @description Método que sirve para la paginación de la tabla
         */
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },

        /**
         * @method loadModalData Este método se ejecuta cuando se abre el modal de busqueda avanzada.
         * @description Este método se ejecuta cuando se abre el modal de busqueda avanzada. Se realizan las peticiones para llenar los campos que se requieran dependiendo del rol.
         * Se ejecuta como emit del componente tabla.
         */
        loadModalData() {
            this.resetValue();
            let cliente = null;
            let empresa = null;
            let sucursal = null;
            let depto = null;

           if (this.rol == "admin-depto") {
                depto = this.datosLogin.departamento_id;
            }
        },

        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValue() {
            this.horario.id = 0;
            this.horario.nombre = "";
            this.horario.descripcion = "";
            this.horario.minutos_tolerancia = "";
            this.horario.cliente_id = "";
            this.horario.empresa_id = "";
            this.horario.sucursal_id = "";
            this.horario.detallesHorarios = [];
            this.horario.detalles_eliminados = [];
            this.horario.jornada_id = null;
            this.dia = "";
            this.hora_entrada = "";
            this.hora_salida = "";
            this.semana = [
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado",
                "Domingo",
            ];
            this.semanaHorarios = [
                { dia: "Lunes", desabilitar: false },
                { dia: "Martes", desabilitar: false },
                { dia: "Miércoles", desabilitar: false },
                { dia: "Jueves", desabilitar: false },
                { dia: "Viernes", desabilitar: false },
                { dia: "Sábado", desabilitar: false },
                { dia: "Domingo", desabilitar: false },
            ];
            
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 

                if(this.rol == 'root'){
                    this.cliente_value      = null;
                    this.empresa_value      = null;
                    this.sucursal_value     = null;
                    this.empresas           = [];
                    this.sucursales         = [];
                }

                if(this.rol == 'admin'){
                    this.empresa_value      = null;
                    this.sucursal_value     = null;
                    this.sucursales         = [];
                } 

                if(this.rol == 'admin-empresa'){
                    this.sucursal_value     = null;
                }

                this.dias_seleccionados = [];
                this.jornada_id = null;
                this.nombre = null;
                this.departamentos = [];
                this.loading_guardar = false;
            });
        },

        /**
         * @method setFilters Método para el filtrado.
         * @description Este método se ejecuta cuando se le da click al botón "buscar". Se realiza la petición con base a los campos solicitados.
         */
        setFilters() {
            this.filters = { AND:[]};

            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value;
            let jornada = this.jornada_id;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            }else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } 
            
            
            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                    this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
                }

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }

            if(sucursal != null && sucursal != undefined && sucursal != ""){
                this.filters.AND.push({column:'SUCURSAL_ID', value:sucursal});
            }

            if(jornada != null && jornada != undefined && jornada != ""){
                this.filters.AND.push({column:'JORNADA_ID', value:jornada});
            }
            
            this.paginationData.numberPage = 1;
            this.$apollo.queries.horarios.refetch();
            this.resetValue();
        },

        /**
         * @method detectarNavegador Método para detectar navegador
         * @description Este método sirve para detectar el tipo de navedaro que se utiliza, esto para seleccionar el tipo de componente tipo hora en el modal.
         */
        detectarNavegador(){
            var sBrowser, sUsrAg = navigator.userAgent;
                if(sUsrAg.indexOf("Chrome") > -1) {
                    sBrowser = "Google Chrome";
                    this.navegador = false;
                } else if (sUsrAg.indexOf("Safari") > -1) {
                    sBrowser = "Apple Safari";
                    this.navegador = true;
                } else if (sUsrAg.indexOf("Opera") > -1) {
                    sBrowser = "Opera";
                    this.navegador = false;
                } else if (sUsrAg.indexOf("Firefox") > -1) {
                    sBrowser = "Mozilla Firefox";
                    this.navegador = false;
                } else if (sUsrAg.indexOf("MSIE") > -1) {
                    sBrowser = "Microsoft Internet Explorer";
                    this.navegador = false;
                }
        }, 

        /**
         * @method abrirModal Método para abrir el modal de horarios
         * @description Método para abrir el modal de horarios. Modal para agregar o actualizar un horario.
         * @param {string} accion La acción puede ser "add" o "update"
         * @param {object} item Objeto que esta en null si la acción es "add"; item tiene los datos de un horario para que se pinten en el modal.
         */
        abrirModal(accion, item = null) {
            let self = this;
            this.accion = accion;
            this.finaliza_carga = false
            this.detectarNavegador();
            if (accion == "add") {
                this.titulo_modal = "Registrar horario";
                this.modalImagen = "/static/icon/horarioCreate.svg"
                let ordenaDetalle_horario = [];
                for (var i = 0; i < self.semanaOriginal.length; i++) {
                    for (var j = 0; j < self.horario.detallesHorarios.length; j++) {
                        if (self.semanaOriginal[i] === self.horario.detallesHorarios[j].dia) {
                            ordenaDetalle_horario.push(self.horario.detallesHorarios[j]);
                        }
                    }
                }
                self.horario.detallesHorarios = ordenaDetalle_horario;
                self.horario.detallesHorarios.forEach((element) => {
                    self.semanaHorarios.forEach((el) => {
                        if (element.dia == el.dia) {
                            el.desabilitar = true;
                        }
                    });
                });
                self.horario.detallesHorarios.forEach(function(element) {
                    if (element.dia === "Lunes") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                    if (element.dia === "Martes") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                    if (element.dia === "Miércoles") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                    if (element.dia === "Jueves") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                    if (element.dia === "Viernes") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                    if (element.dia === "Sábado") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                    if (element.dia === "Domingo") {
                        let index = self.semana.indexOf(element.dia);
                        self.semana.splice(index, 1);
                    }
                });
                this.finaliza_carga = true;
            } else {
                this.titulo_modal = "Actualizar horario";
                this.modalImagen = "/static/icon/horarioUpdate.svg"

                horariosApi.getHorario(item.id).then((response) => {
                    self.horario.id = response.id;
                    self.horario.nombre = response.nombre;
                    self.horario.descripcion = response.descripcion;
                    self.horario.minutos_tolerancia = response.minutos_tolerancia;
                    self.cliente_value = response.cliente_id;
                    self.empresa_value = response.empresa_id;
                    self.horario.sucursal_id = response.sucursal_id;
                    self.horario.detallesHorarios = response.detalles_horarios;
                    self.horario.jornada_id = response.jornada_id;
                    if (response.cliente != undefined) {
                        self.horario.cliente_id = response.cliente_id;
                    }
                    if (response.empresa != undefined) {
                        self.horario.empresa_id = response.empresa_id;
                    }
                    if (response.sucursal != undefined) {
                        self.horario.sucursal_id = response.sucursal_id;
                    }

                    let ordenaDetalle_horario = [];
                    for (var i = 0; i < self.semanaOriginal.length; i++) {
                        for (var j = 0; j < self.horario.detallesHorarios.length; j++) {
                            if (self.semanaOriginal[i] ===self.horario.detallesHorarios[j].dia) {
                                ordenaDetalle_horario.push(self.horario.detallesHorarios[j]);
                            }
                        }
                    }
                    self.horario.detallesHorarios = ordenaDetalle_horario;
                    self.horario.detallesHorarios.forEach((element) => {
                        self.semanaHorarios.forEach((el) => {
                            if (element.dia == el.dia) {
                                el.desabilitar = true;
                            }
                        });
                    });
                    self.horario.detallesHorarios.forEach(function(element) {
                        if (element.dia === "Lunes") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                        if (element.dia === "Martes") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                        if (element.dia === "Miércoles") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                        if (element.dia === "Jueves") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                        if (element.dia === "Viernes") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                        if (element.dia === "Sábado") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                        if (element.dia === "Domingo") {
                            let index = self.semana.indexOf(element.dia);
                            self.semana.splice(index, 1);
                        }
                    });
                    this.finaliza_carga = true;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading_guardar = false;
                    Notify.ErrorToast("Ocurrió un error al cargar los horarios.");
                });
            }
            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.horario.sucursal_id = this.datosLogin.sucursal_id;
            }
            setTimeout(function() {
                document.getElementById("btnModal").click();
            }, 100);
        },
        
        /**
         * @method agregarHorario Método para agregar un horario por día
         * @description Este método sirve para agregar horarios por los días seleccionados
         */
        agregarHorario() {
            let self = this;
            if (
                this.dias_seleccionados != "" &&
                this.hora_entrada != "" &&
                this.hora_salida != ""
            ) {
                var hr_entrada = parseInt(this.hora_entrada.substring(0, 2));
                var min_entrada = parseInt(this.hora_entrada.substring(2, 4));
                var hr_salida = parseInt(this.hora_salida.substring(0, 2));
                var min_salida = parseInt(this.hora_salida.substring(2, 4));
                if (
                    hr_entrada > 23 ||
                    min_entrada > 60 ||
                    hr_salida > 23 ||
                    min_salida > 60
                ) {
                    Notify.Warning("Hora incorrecta", "La hora registrada es incorrecta" );
                } else {
                    if (!this.jornada_nocturna && (this.hora_salida <= this.hora_entrada)) {
                        Notify.Warning("Hora incorrecta", "La hora de salida debe ser mayor a la hora de entrada. Formato de hora hh:mm, Ejemplos: 09:00 - 13:00" );
                    } else {
                        if (this.hora_entrada.length < 4 || this.hora_salida.length < 4) {
                            Notify.Warning("Formato de hora incorrecta", "Formato de hora hh:mm, Ejemplos: 09:00 - 13:00" );
                        } else {
                            this.hora_entrada =
                                this.hora_entrada.substring(0, 2) +
                                ":" +
                                this.hora_entrada.substring(3, 5) +
                                ":00";

                            this.hora_salida =
                                this.hora_salida.substring(0, 2) +
                                ":" +
                                this.hora_salida.substring(3, 5) +
                                ":00";
                            this.dias_seleccionados.forEach((element) => {
                                let detalle_horario = {
                                    id: 0,
                                    dia: element,
                                    hora_entrada: this.hora_entrada,
                                    hora_salida: this.hora_salida,
                                }; //Se agrega el id:0
                                this.horario.detallesHorarios.push(detalle_horario);
                                let ordenaDetalle_horario = [];
                                for (var i = 0; i < this.semanaOriginal.length; i++) {
                                    for (
                                        var j = 0;
                                        j < this.horario.detallesHorarios.length;
                                        j++
                                    ) {
                                        if (this.semanaOriginal[i] === this.horario.detallesHorarios[j].dia) {
                                            ordenaDetalle_horario.push(
                                                this.horario.detallesHorarios[j]
                                            );
                                        }
                                    }
                                }
                                this.horario.detallesHorarios = ordenaDetalle_horario;
                            });
                            this.horario.detallesHorarios.forEach((element) => {
                                this.semanaHorarios.forEach((el) => {
                                    if (element.dia == el.dia) {
                                        el.desabilitar = true;
                                    }
                                });
                            });
                            this.dias_seleccionados = [];
                            this.hora_entrada = "";
                            this.hora_salida = "";
                        }
                    }
                }
            }
            else{
                if(this.dias_seleccionados.length == 0){
                    Notify.Warning("Seleccionar días", "Seleccionar al menos un día." );
                }
                else if( this.hora_entrada == "" || this.hora_salida == ""){
                    Notify.Warning("Formato hora", "Ingresar un horario correcto. Ejemplo: 12:30." );
                }                
            }
        },

        /**
         * @method eliminaHorario Método para eliminar un horario de un día
         * @description Este método sirve para eliminar un horario de un día
         * @param {index} index index del elemento en el array
         * @param {id} id id del registro a eliminar, se almacena en un array "detalles_eliminados"
         */
        eliminaHorario(index, id) {
            if (id != 0) {
                let id_dia = { eliminado_id: id };
                this.horario.detalles_eliminados.push(id_dia);
            }
            let dia = this.horario.detallesHorarios[index].dia;
            this.semanaHorarios.forEach((el) => {
                if (dia == el.dia) {
                    el.desabilitar = false;
                }
            });
            this.horario.detallesHorarios.splice(index, 1);
        },

        /**
         * @method guardar Este método guarda los datos que se necesitan para el registro de un horario.
         * @description Este método guarda los datos que se necesitan para un horario.
         * El método se llama para crear o actulizar un registro para un horario. En caso de que la petición
         * sea correcta la tabla se actualiza.
         */
        guardar() {
            this.horario.cliente_id = this.cliente_value;
            this.horario.empresa_id = this.empresa_value;
            this.$refs.form.validate().then(success => {
                this.loading_guardar = true;
                if (!success) {
                    this.loading_guardar = false;
                    return;
                }
                    
                this.horario.minutos_tolerancia = parseInt(
                    this.horario.minutos_tolerancia,
                    10
                );

                if(this.horario.detallesHorarios.length == 0){
                    Notify.ErrorToast("Es requerido agregar al menos un día con horario");
                    this.loading_guardar = false;
                }else{
                    this.horario.detalles_horario = this.horario.detallesHorarios;
                    if (this.accion === "add") {
                        horariosApi.addHorario(this.horario).then((response) => {
                            this.isLoading = true;
                            this.$apollo.queries.horarios.refetch();
                            this.respuesta_ok("El horario se guardó satisfactoriamente.");
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            this.respuesta_error(err);
                        });
                    } else if (this.accion === "update") {
                        horariosApi.updateHorario(this.horario).then((response) => {
                            this.isLoading = true;
                            this.$apollo.queries.horarios.refetch();
                            this.respuesta_ok("El horario se actualizó satisfactoriamente.");
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            this.respuesta_error(err);
                        });
                    }
                }
            });
            this.semana = [
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado",
                "Domingo",
            ];
        },

        /**
         * @method getClientes Método para clientes
         * @description Método para hacer petición a la API de clientes
         */
        async getClientes(clientes = null) {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method cambiaCliente Este método realiza la petición a la api de empresas.
         * @description Este método realiza la petición a la api de empresas, esta petición sirve para llenar el campo de empresas en la parte de los filtros y el modal agregar/update.
         * @param {int} cliente id de cliente, sirve para enviar en los paramentros de la api de empresas.
         */
        cambiaCliente(cliente, accion = null) {
            if(cliente == null){
                return;
            }
            let param = { activo: true, paginate: false, cliente_id: cliente };
            empresaApi.getEmpresas(param).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method cambiaEmpresa Este método realiza la petición a la api de sucursales.
         * @description Este método realiza la petición a la api de sucursales, esta petición sirve para llenar el campo de sucursales.
         * @param {int} empresa id de empresa, sirve para enviar en los paramentros de la api de sucursales.
         */
        cambiaEmpresa(empresa, accion = null) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        /**
         * @method getTipoJornada Este método realiza la petición a la api de tipo de jornadas.
         * @description Este método realiza la petición a la api de tipo de jornadas.
         */
        getTipoJornada() {
            let parametros = { activo: true, paginate: false, order: "asc" };
            apiTipoJornada.find(parametros).then((response) => {
                this.jornadas = response.data;
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las jornadas.");
            });
        },

        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValue() para resetear los valores.
         */
        cerrarModal() {
            this.dialog = false;
            this.resetValue();
        },

        /**
         * @method respuesta_error Método para mensaje de errores
         * @description Método para mostrar los errores mediante una notificación.
         * @param {string, object} err El error puede ser de tipo string o de tipo objeto. 
         */
        respuesta_error(err) {
            this.loading_guardar = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },

        /**
         * @method respuesta_ok Método para mostrar un mensaje exitoso
         * @description Método para mostrar un mensaje de opercación exitosa.
         * @param {string} msj Mensaje para mostrar en modal. 
         */
        respuesta_ok(msj = "") {
            //this.listar();
            this.cerrarModal();
            Notify.Success("Operación exitosa", msj);
        },

        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa, admin-sucursal
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("horarios.store")
            this.permisos.update = permisosStorage.includes("horarios.update")
            this.permisos.delete = permisosStorage.includes("horarios.delete")
        }
    },

    /**
     * @description mounted(). Se inician los valores que se requieren al montar los componentes en la vista.
    */
    mounted () {
        this.$apollo.queries.horarios.skip = false;
    },

    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created() {
        this.listar();
        this.permisosVista();
    },

    /**
     * @name apollo:horarios
     * @description GraphQL apollo, query horarios, realiza la petición a horarios y los datos se utilizan en el componente tabla. 
     */
    apollo: {
        horarios: {
            query       : queries.horariosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.horarios;   
            },
        }
    }
};
</script>
<style scoped>
.centrar {
    height: 90px;
    width: 500px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 25px;
}
.dias {
    height: 35px;
}
.selDias {
    margin-top: -10px;
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #A0A4A8;
    margin-bottom: 15px;
}

.detalle {
    margin-top: -30px !important;
}
</style>
